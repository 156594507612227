import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Privacy Policy</h1>

      <section>
        <h2 style={styles.heading}>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h2>
        <p style={styles.paragraph}>
          When you interact with our website (<a href="https://www.codexodus.com" style={styles.link}>www.codexodus.com</a>), as part of providing our services, we collect personal information such as your name, email address, and any other details you provide when creating an account, submitting forms, or contacting us.
        </p>
        <p style={styles.paragraph}>
          When you browse our website, we also automatically receive your computer’s internet protocol (IP) address to provide us with information that helps us learn about your browser and operating system.
        </p>
        <p style={styles.paragraph}>
          With your permission, we may send you emails about our services, new features, updates, and other relevant information.
        </p>
      </section>

      <section>
        <h2 style={styles.heading}>SECTION 2 - CONSENT</h2>
        <h3 style={styles.subHeading}>How do you get my consent?</h3>
        <p style={styles.paragraph}>
          When you provide us with personal information to create an account, complete a transaction, or access our services, we imply that you consent to our collecting it and using it for that specific purpose only.
        </p>
        <p style={styles.paragraph}>
          If we ask for your personal information for a secondary purpose, like marketing, we will either ask you directly for your express consent or provide you with an option to decline.
        </p>
        <h3 style={styles.subHeading}>How do I withdraw my consent?</h3>
        <p style={styles.paragraph}>
          If, after opting in, you change your mind, you may withdraw your consent for us to contact you or for the continued collection, use, or disclosure of your information at any time by contacting us at{" "}
          <a href="mailto:support@codexodus.com" style={styles.link}>support@codexodus.com</a> or by mailing us at:
        </p>
        <address style={styles.address}>
          Michael International<br />
          11-72 H, Michael Conclave, Star Lane, Azhagappapuram,<br />
          Kanyakumari District, Tamil Nadu - 629401, India
        </address>
      </section>

      <section>
        <h2 style={styles.heading}>SECTION 3 - DISCLOSURE</h2>
        <p style={styles.paragraph}>
          We may disclose your personal information if required by law or if you violate our Terms of Service.
        </p>
      </section>

      <section>
        <h2 style={styles.heading}>SECTION 4 - PAYMENT</h2>
        <p style={styles.paragraph}>
          We use Razorpay to process payments. Neither we nor Razorpay store your card data on our servers. Your data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) while processing payments. Your transaction data is used only as necessary to complete your transaction and is not retained afterward.
        </p>
        <p style={styles.paragraph}>
          Razorpay adheres to PCI-DSS standards managed by the PCI Security Standards Council, which ensures secure handling of credit card information. For more information, read Razorpay’s terms and conditions at{" "}
          <a href="https://razorpay.com" style={styles.link}>https://razorpay.com</a>.
        </p>
      </section>

      <section>
        <h2 style={styles.heading}>SECTION 5 - THIRD-PARTY SERVICES</h2>
        <p style={styles.paragraph}>
          In general, the third-party providers used by us will only collect, use, and disclose your information to the extent necessary to perform the services they provide.
        </p>
        <p style={styles.paragraph}>
          Certain third-party service providers, such as payment gateways, have their own privacy policies regarding the information we provide them. For these providers, we recommend that you read their privacy policies to understand how your information is handled.
        </p>
        <p style={styles.paragraph}>
          Please note that some providers may be located in jurisdictions different from yours. If you proceed with a transaction involving a third-party provider, your information may be subject to the laws of the jurisdiction(s) where the provider or its facilities are located.
        </p>
        <p style={styles.paragraph}>
          Once you leave our website or are redirected to a third-party site or application, this Privacy Policy no longer applies.
        </p>
      </section>

      <section>
        <h2 style={styles.heading}>SECTION 6 - SECURITY</h2>
        <p style={styles.paragraph}>
          To protect your personal information, we take reasonable precautions and follow industry best practices to ensure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.
        </p>
      </section>

      <section>
        <h2 style={styles.heading}>SECTION 7 - COOKIES</h2>
        <p style={styles.paragraph}>
          We use cookies to maintain your session on our platform. Cookies are not used to personally identify you on other websites.
        </p>
      </section>

      <section>
        <h2 style={styles.heading}>SECTION 8 - AGE OF CONSENT</h2>
        <p style={styles.paragraph}>
          By using this website, you confirm that you are at least the age of majority in your state or province of residence or that you have given us your consent to allow your minor dependents to use this website.
        </p>
      </section>

      <section>
        <h2 style={styles.heading}>SECTION 9 - CHANGES TO THIS PRIVACY POLICY</h2>
        <p style={styles.paragraph}>
          We reserve the right to modify this Privacy Policy at any time. Changes and clarifications will take effect immediately upon being posted on the website. If material changes are made to this policy, we will notify you here so that you are aware of what information we collect, how we use it, and under what circumstances we disclose it.
        </p>
        <p style={styles.paragraph}>
          If Michael International is acquired or merged with another company, your information may be transferred to the new owners to continue providing services to you.
        </p>
      </section>

      <section>
        <h2 style={styles.heading}>QUESTIONS AND CONTACT INFORMATION</h2>
        <p style={styles.paragraph}>
          If you would like to access, correct, amend, or delete any personal information we have about you, register a complaint, or request more information, contact our Privacy Compliance Officer at:
        </p>
        <p style={styles.paragraph}>
          Email: <a href="mailto:support@codexodus.com" style={styles.link}>support@codexodus.com</a>
        </p>
        <address style={styles.address}>
          Michael International<br />
          11-72 H, Michael Conclave, Star Lane, Azhagappapuram,<br />
          Kanyakumari District, Tamil Nadu - 629401, India
        </address>
      </section>
    </div>
  );
};

const styles = {
  container: {
   
    margin: "20px auto",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    fontFamily: "Arial, sans-serif",
    marginTop:'60px'
  },
  title: {
    textAlign: "center",
    color: "#333",
  },
  heading: {
    color: "#555",
  },
  subHeading: {
    color: "#666",
    marginTop: "10px",
  },
  paragraph: {
    color: "#444",
    lineHeight: "1.6",
    marginBottom: "10px",
  },
  link: {
    color: "#007BFF",
    textDecoration: "none",
  },
  address: {
    color: "#444",
    lineHeight: "1.6",
  },
};

export default PrivacyPolicy;
