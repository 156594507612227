import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./Common/Footer";
import Navbar from "./Common/Navbar";
import ContactUs from "./Pages/ContactUs";
import whatsappIcon from "./Images/whatsapp.svg";
import HomePageLayout from "./Pages/Layout";
import Terms from "./Pages/TermsAndConditions";
import Courser from "./Courser";
import useWindowSize from "./useWindowSize"; // Import the custom hook
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function App() {
  const { width } = useWindowSize();

  return (
    <Router>
      <Navbar />
      {width > 768 && <Courser />} {/* Only render Courser on larger screens */}
      <Routes>
        <Route path="/" element={<HomePageLayout />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <a
        href="https://wa.me/919894079619"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsappIcon} alt="WhatsApp" />
      </a>
      <Footer />
    </Router>
  );
}

export default App;
