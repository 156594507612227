

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./StatisticsCard.css";

const StatisticsCard = () => {
  // Initialize AOS when the component mounts
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true, // Animation triggers only once
    });
  }, []);

  return (
    <div className="statistics">
      <div className="statistics-card">
        <div className="statistics-card__item" data-aos="fade-up">
          <h2 className="statistics-card__number">2000+</h2>
          <p className="statistics-card__label">LEARNERS</p>
        </div>
        <div
          className="statistics-card__item"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h2 className="statistics-card__number">10+</h2>
          <p className="statistics-card__label">YEARS</p>
        </div>
        <div
          className="statistics-card__item"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <h2 className="statistics-card__number">10+</h2>
          <p className="statistics-card__label">CODING LANGUAGES</p>
        </div>
        <div
          className="statistics-card__item"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          <h2 className="statistics-card__number">100%</h2>
          <p className="statistics-card__label">TRUSTED</p>
        </div>
      </div>
    </div>
  );
};

export default StatisticsCard;
