import React from "react";

const TermsAndConditions = () => {
  return (
    <div style={{ padding: "40px", paddingTop: "100px" }}>
      <h2>Terms & Conditions</h2>
      <p>Last updated on 16-10-2024 12:14:28</p>

      <p>
        These Terms and Conditions, along with the privacy policy or other terms
        (“Terms”) constitute a binding agreement by and between 
        CodeXodus (“Website Owner” or “we” or “us” or “our”) and you (“you”
        or “your”) and relate to your use of our website, goods (as applicable),
        or services (as applicable) (collectively, “Services”).
      </p>

      <p>
        By using our website and availing the Services, you agree that you have
        read and accepted these Terms (including the Privacy Policy). We reserve
        the right to modify these Terms at any time and without assigning any
        reason. It is your responsibility to periodically review these Terms to
        stay informed of updates.
      </p>

      <h3>Terms of Use:</h3>

      <p>
        To access and use the Services, you agree to provide true, accurate, and
        complete information to us during and after registration, and you shall
        be responsible for all acts done through the use of your registered
        account.
      </p>

      <p>
        Neither we nor any third parties provide any warranty or guarantee as to
        the accuracy, timeliness, performance, completeness, or suitability of
        the information and materials offered on this website or through the
        Services, for any specific purpose.
      </p>

      <p>
        Your use of our Services and the website is solely at your own risk and
        discretion. You are required to independently assess and ensure that the
        Services meet your requirements.
      </p>

      <p>
        The contents of the Website and the Services are proprietary to Us, and
        you will not have any authority to claim any intellectual property
        rights, title, or interest in its contents.
      </p>

      <h3>Payment and Charges:</h3>
      <p>
        You agree to pay us the charges associated with availing the Services.
      </p>

      <h3>Prohibited Use:</h3>
      <p>
        You agree not to use the website and/or Services for any purpose that is
        unlawful, illegal, or forbidden by these Terms, or Indian or local laws
        that might apply to you.
      </p>

      <h3>Third-Party Links:</h3>
      <p>
        You agree and acknowledge that the website and the Services may contain
        links to other third-party websites. On accessing these links, you will
        be governed by the terms of use, privacy policy, and such other policies
        of such third-party websites.
      </p>

      <h3>Refunds:</h3>
      <p>
        You shall be entitled to claim a refund of the payment made by you in
        case we are not able to provide the Service. The timelines for such
        return and refund will be according to the specific Service you have
        availed or within the time period provided in our policies (as
        applicable).
      </p>

      <h3>Force Majeure:</h3>
      <p>
        Notwithstanding anything contained in these Terms, the parties shall not
        be liable for any failure to perform an obligation under these Terms if
        performance is prevented or delayed by a force majeure event.
      </p>

      <h3>Governing Law and Jurisdiction:</h3>
      <p>
        These Terms and any dispute or claim relating to it, or its
        enforceability, shall be governed by and construed in accordance with
        the laws of India. All disputes arising out of or in connection with
        these Terms shall be subject to the exclusive jurisdiction of the courts
        in Nungambakkam, Tamil Nadu.
      </p>

      <p>
        All concerns or communications relating to these Terms must be
        communicated to us using the contact information provided on this
        website.
      </p>
    </div>
  );
};

export default TermsAndConditions;
