import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./AvailableCourses.css";
import CourseImage1 from "../Images/C.svg";
import CourseImage2 from "../Images/cplusplus.svg";
import CourseImage3 from "../Images/Csharp.svg"; // Adjust image path
import CourseImage4 from "../Images/APP.svg";
import CourseImage5 from "../Images/DataStructures.svg";
import CourseImage6 from "../Images/GO.svg";
import CourseImage7 from "../Images/haskell.svg";
import CourseImage8 from "../Images/Java.svg";
import CourseImage9 from "../Images/Julia.svg";
import CourseImage10 from "../Images/LUA.svg";
import CourseImage11 from "../Images/OOPS.svg";
import CourseImage12 from "../Images/OS.svg";
import CourseImage13 from "../Images/Perl.svg";
import CourseImage14 from "../Images/Python.svg";
import CourseImage15 from "../Images/R.svg";
import CourseImage16 from "../Images/Ruby.svg";
import CourseImage17 from "../Images/Rust.svg";
import CourseImage18 from "../Images/swift.svg";
import CourseImage19 from "../Images/compilerdesign.svg";
import CourseImage20 from "../Images/CN.svg";
import CourseImage21 from "../Images/octave.svg";
import CourseImage22 from "../Images/DAA.svg";
import Html from "../Images/html.svg";
import css from "../Images/css.svg";

import CourseImage from "../Images/CN.svg";

const AvailableCourses = () => {
  const courses = [
    {
      id: 1,
      title: "C Programming",
      description: `C is a foundational language crucial for understanding core computer science and low-level programming,
serving as a stepping stone to more advanced languages.`,
      rating: 5,
      price: "₹1222/-",
      image: CourseImage1,
    },
    {
      id: 1,
      title: "C++ Programming",
      description: `C++ excels in system programming, game development, and high-performance applications,
making it a versatile and essential tool in software development.`,
      rating: 5,
      price: "₹1222/-",
      image: CourseImage2,
    },

    {
      id: 1,
      title: "Java Programming",
      description: `Java's platform independence and versatility make it ideal for enterprise applications and Android development,
offering access to high-demand career opportunities.`,
      rating: 5,
      price: "₹1222/-",
      image: CourseImage8,
    },
    {
      id: 1,
      title: "Python Programming",
      description: `
Python's simplicity and extensive libraries make it ideal for data science, AI, and web development,
offering flexibility across diverse fields.`,
      rating: 5,
      price: "₹1222/-",
      image: CourseImage14,
    },

    {
      id: 1,
      title: "Data Structures",
      description: `Mastering data structures is essential for organizing and manipulating data efficiently,
critical for algorithms, problem-solving, and building scalable software.`,
      rating: 5,
      price: "₹1222/-",
      image: CourseImage5,
    },

//     {
//       id: 1,
//       title: "Design & Analysis of Algorithms",
//       description: `
// Master efficient algorithms to solve complex problems and optimize performance,
// essential for advanced problem-solving and programming.`,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage22,
//     },
//     {
//       id: 1,
//       title: "Object-Oriented Programming",
//       description: `Use OOP principles like inheritance, encapsulation, and polymorphism to create organized, modular,
// reusable, and maintainable software.
// `,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage11,
//     },

//     {
//       id: 1,
//       title: "C# Programming",
//       description: `

//      C# is a versatile programming language for game development, desktop apps, and enterprise software,
// ideal for scalable, high-performance applications`,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage3,
//     },
//     {
//       id: 1,
//       title: "R Programming",
//       description: `
// R is essential for data scientists and statisticians, widely used in statistics and data analysis.
// It features a rich ecosystem of packages for visualizing, analyzing, and modeling data.`,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage15,
//     },
    {
      id: 1,
      title: "Go Programming",
      description: `
Created by Google, Go excels in building scalable web servers and cloud computing.
Its simplicity and performance make it ideal for modern infrastructure.






`,
      rating: 5,
      price: "₹1222/-",
      image: CourseImage6,
    },
//     {
//       id: 1,
//       title: "Perl Programming",
//       description: `Perl is known for its powerful text-processing capabilities and is used in web development and system administration.
// Its flexibility makes it valuable for automating tasks.`,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage13,
//     },
    {
      id: 1,
      title: "Ruby Programming",
      description: `

      Ruby's simplicity and the powerful Rails framework make it popular for web application development.
      It's widely favored by startups for its high productivity and agility.`,
      rating: 5,
      price: "₹1222/-",
      image: CourseImage16,
    },

//     {
//       id: 1,
//       title: "Octave Programming",
//       description: `Octave is an open-source alternative to MATLAB, ideal for numerical analysis, scientific computation, and data.
// Its compatibility with MATLAB syntax makes it popular in academia.






// `,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage21,
//     },
    {
      id: 1,
      title: "Lua Programming",
      description: `Lightweight and embeddable, Lua is used in game development and embedded systems for scripting.
Its adaptability makes it a top choice for applications.`,
      rating: 5,
      price: "₹1222/-",
      image: CourseImage10,
    },

//     {
//       id: 1,
//       title: "Haskell Programming",
//       description: `Haskell is a functional programming language for complex calculations and systems requiring reliability.
// Its purity and mathematical foundation make it suitable for verification.






// `,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage7,
//     },

//     {
//       id: 1,
//       title: "Rust Programming",
//       description: `Rust prioritizes performance and safety, making it ideal for system programming.
// Its "zero-cost abstractions" attract developers of secure software.
// `,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage17,
//     },
//     {
//       id: 1,
//       title: "Julia Programming",
//       description: `Julia is a high-performance language for scientific computing, data analysis, and machine learning.
// Its speed and ease of use make it ideal for data-heavy applications.`,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage9,
//     },
//     {
//       id: 1,
//       title: "Swift Programming",
//       description: `Swift is Apple’s language for iOS and macOS development, known for its speed and ease of use.
// It’s essential for building efficient, user-friendly apps in the Apple ecosystem.


// `,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage18,
//     },

//     {
//       id: 1,
//       title: "Operating Systems",
//       description: `Learn the core components and functionalities of operating systems, crucial for systems programming.
// This course provides insights into software and hardware interaction.`,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage12,
//     },
//     {
//       id: 1,
//       title: "Computer Networks",
//       description: `Explore networking concepts and protocols for global communication, vital for IT and security.
// Network knowledge is essential for cybersecurity and cloud computing.





// `,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage20,
//     },
//     {
//       id: 1,
//       title: "Compiler Design",
//       description: `Learn how compilers translate high-level code to machine language.
// This course is essential for those interested in building programming languages and interpreters.
// `,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage19,
//     },

//     {
//       id: 1,
//       title: "Advanced Programming Practices",
//       description: `Explore advanced coding techniques to enhance efficiency and security.
// This course elevates your skills to professional standards.`,
//       rating: 5,
//       price: "₹1222/-",
//       image: CourseImage4,
//     },
    {
      id: 1,
      title: "HTML Web Development",
      description: `HTML is the backbone of web pages, essential for structuring website content.
It’s the first step in creating interactive and accessible web experiences.






`,
      rating: 5,
      price: "₹1222/-",
      image: Html,
    },
    {
      id: 1,
      title: "CSS Web Development",
      description: `CSS adds style and visual structure to web pages, making them attractive and user-friendly.
Mastering CSS is essential for designing visually appealing and responsive websites.`,
      rating: 5,
      price: "₹1222/-",
      image: css,
    },
  ];

  // Initialize AOS when the component mounts
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true, // Animates only once
    });
  }, []);

  return (
    <div style={{backgroundColor:'white'}}>
      <h1 className="available-courses__title" data-aos="fade-up">
        Available Courses
      </h1>
      <section className="available-courses">
        <div className="available-courses__grid">
          {courses.map((course, index) => (
            <div
              key={index}
              className="available-courses__card"
              data-aos="zoom-in"
              data-aos-delay={`${index * 100}`}
            >
              <img
                src={course.image}
                alt={course.title}
                className="available-courses__image"
              />
              <h2 className="available-courses__card-title">{course.title}</h2>
              <p className="available-courses__rating">⭐⭐⭐⭐⭐</p>
              <p className="available-courses__description">
                {course.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AvailableCourses;
